/**
 * 页面示例-路由配置
 */
import LayoutIndex from '@/layout/Index';
import AppMainLayout from '@/layout/components/AppMain';

const websiteRouter = {
  path: '/website',
  name: 'website',
  component: LayoutIndex,
  redirect: '/website/manager/list',
  meta: { title: '控制台', icon: 'page', roles: ['admin'] },
  children: [
    {
      path: 'manager',
      name: 'manager',
      redirect: '/website/manager/list',
      component: AppMainLayout,
      meta: { title: '管理员', icon: 'pagelist', roles: ['admin'] },
      children: [
        {
          path: 'list',
          name: 'manager-list',
          meta: { title: '管理员列表', icon: 'pagelist', roles: ['admin'] },
          component: () => import('@/views/Website/Manager/list')
        }, {
          path: 'edit',
          name: 'manager-edit',
          hidden: true,
          meta: {
            title: '管理员编辑', icon: 'lock',
            roles: ['admin'], noCache: true,
            activeMenu: '/website/manager/list'
          },
          component: () => import('@/views/Website/Manager/edit')
        }
      ]
    }
  ]

};

export default websiteRouter;