<template>
    <div class="indexlayout-right-footer">
        <div class="footer-links">
            <a href="http://naitcor.com" target="_blank">NaitCor</a>
            <a href="mailto:2296316065@qq.com" target="_blank">联系开发人员</a>
        </div>
        <div class="footer-copyright">
            Copyright © 2021 naitcor.com, All Rights Reserved
        </div>
    </div>
</template>
<script>
export default {
    name: 'LayoutIndexRightFooter'
};
</script>
<style lang="scss" scoped>
.indexlayout-right-footer {
    padding: 48px 0 24px;
    height: 50px;
    overflow: hidden;
    text-align: center;
    font-size: 14px;
    color: #808695;
    .footer-links {
        margin-bottom: 10px;
        a {
            margin-right: 30px;
            color: #808695;
            text-decoration: none;
            &:last-child {
                margin-right: 0;
            }
            &:hover{
                color: #515a6e;
            }
        }
    }
}
</style>