/**
 * 页面示例-路由配置
 */
import LayoutIndex from '@/layout/Index';
import AppMainLayout from '@/layout/components/AppMain';

const webcontentRouter = {
  path: '/app',
  name: 'app',
  component: LayoutIndex,
  redirect: '/app/article/caselist',
  meta: { title: '小程序应用', icon: 'page', roles: ['admin', 'normal'] },
  children: [
    {
      path: 'article',
      name: 'article',
      redirect: '/app/article/case/caselist',
      component: AppMainLayout,
      meta: { title: '案例管理', icon: 'pagelist', roles: ['admin', 'normal'] },
      children: [
        {
          path: 'list/categories:type1',
          name: 'categories',
          meta: { title: '案例分类', icon: 'pagelist', roles: ['admin', 'normal'] },
          query: {
            type: 1
          },
          component: () => import('@/views/ArticleCategory/list')
        },
        {
          path: 'categoryedit',
          name: 'categoryedit',
          hidden: true,
          meta: { title: '案例分类编辑', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/ArticleCategory/edit')
        },
        {
          path: 'caselist',
          name: 'case-list',
          meta: { title: '案例列表', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Article/case/list')
        },
        {
          path: 'caseedit',
          name: 'case-edit',
          hidden: true,
          meta: { title: '案例编辑', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Article/case/edit')
        }
      ]
    }, {
      path: 'articleP',
      name: 'articleP',
      redirect: '/app/articleP/product/productlist',
      component: AppMainLayout,
      meta: { title: '产品管理', icon: 'pagelist', roles: ['admin', 'normal'] },
      children: [
        {
          path: 'list/categories:type2',
          name: 'categories',
          meta: { title: '产品分类', icon: 'pagelist', roles: ['admin', 'normal'] },
          query: {
            type: 2
          },
          component: () => import('@/views/ArticleCategory/list')
        },
        {
          path: 'categoryedit',
          name: 'categoryedit',
          hidden: true,
          meta: { title: '产品分类编辑', icon: 'pagelist', roles: ['admin', 'normal'] },

          component: () => import('@/views/ArticleCategory/edit')
        },
        {
          path: 'productlist',
          name: 'product-list',
          meta: { title: '产品列表', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Article/product/list')
        },
        {
          path: 'productedit',
          name: 'product-edit',
          hidden: true,
          meta: { title: '产品编辑', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Article/product/edit')
        }
      ]
    },
    {
      path: 'banner',
      name: 'banner',
      redirect: '/app/banner/list',
      component: AppMainLayout,
      meta: { title: '广告图管理', icon: 'pagelist', roles: ['admin', 'normal'] },
      children: [
        {
          path: 'list',
          name: 'banner-list',
          meta: { title: '广告图列表', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Banner/list')
        }
      ]
    },
    {
      path: 'applyform',
      name: 'applyform',
      redirect: '/app/applyform/list',
      component: AppMainLayout,
      meta: { title: '意向管理', icon: 'pagelist', roles: ['admin', 'normal'] },
      children: [
        {
          path: 'list',
          name: 'applyform-list',
          meta: { title: '意向列表', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/ApplyForm/list')
        }
      ]
    },
    {
      path: 'community',
      name: 'community',
      redirect: '/app/community/list',
      component: AppMainLayout,
      meta: { title: '圈子管理', icon: 'pagelist', roles: ['admin', 'normal'] },
      children: [
        {
          path: 'list',
          name: 'community-list',
          meta: { title: '圈子列表', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Community/list')
        },
        {
          path: 'info',
          name: 'community-info',
          meta: { title: '圈子详细', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Community/info')
        }
      ]
    },
    {
      path: 'appuser',
      name: 'appuser',
      redirect: '/app/appuser/list',
      component: AppMainLayout,
      meta: { title: '会员管理', icon: 'pagelist', roles: ['admin', 'normal'] },
      children: [
        {
          path: 'list',
          name: 'appuser-list',
          meta: { title: '会员管理', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Appuser/list')
        },
        {
          path: 'pointlist',
          name: 'appuser-point-list',
          hidden: true,
          meta: { title: '会员功力值', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Appuser/pointlist')
        },
        {
          path: 'sharelist',
          name: 'appuser-share-list',
          meta: { title: '分享记录', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Appuser/sharelist')
        }
      ]
    }, {
      path: 'settings',
      name: 'settings',
      redirect: '/app/settings/config',
      component: AppMainLayout,
      meta: { title: '系统配置', icon: 'pagelist', roles: ['admin', 'normal'] },
      children: [
        {
          path: 'list',
          name: 'systemconfig',
          meta: { title: '系统配置', icon: 'pagelist', roles: ['admin', 'normal'] },
          component: () => import('@/views/Settings/config')
        }
      ]
    }
  ]

};

export default webcontentRouter;