<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import VueMeta from 'vue-meta';
export default {
  name: 'App',
  metaInfo: {
    title: 'XLearning',
    google: 'notranslate',
    lang: 'zh-CN'
  }
};
</script>
